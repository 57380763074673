import React, { useEffect, useRef } from "react";
import { CircleTextWrapper, DoButtonContainer, RotateWrapper } from "./styled";
import TextAround from "assets/icons/text-around-btn.svg";
import ArrowDown from "assets/icons/arrow-down.svg";

export const DoButton = () => {
  useEffect(() => {
    const id = setInterval(() => {}, 1000);

    return () => clearInterval(id);
  }, []);

  return (
    <DoButtonContainer as="a" href="/projects">
      <CircleTextWrapper data-container>
        <RotateWrapper>
          <TextAround className="circle-text" />
        </RotateWrapper>
        <div className="border-btn" />
        <ArrowDown className="arrow" />
      </CircleTextWrapper>
    </DoButtonContainer>
  );
};
