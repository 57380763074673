import React, { useRef } from "react";
import { Text } from "src/styles/components";
import { CustomLink, Container, Row, Layout, Wrapper, Media } from "./styled";
import ArrowIcon from "assets/icons/link-arrow.svg";
import { Title } from "components/typography";
import { DoButton } from "./doButton";
import { InViewport } from "../inViewport";

export const VideoCard = ({
  title,
  description,
  isRight,
  linkTitle,
  media,
  id,
  isButton,
  isOverflow = true,
  isFancy = false,
  bg,
}) => {
  return (
    <Wrapper bg={bg} id={id}>
      <Container>
        <Layout data-fancy={isFancy}>
          <Row isRight={isRight}>
            <InViewport>
              <CustomLink to="#top" isRight={isRight}>
                <ArrowIcon />
                {linkTitle}
              </CustomLink>
              <Title title={title} />
            </InViewport>
          </Row>
        </Layout>
        <Media isOverflow={isOverflow} isRight={isRight} data-media>
          {media}
        </Media>
        <Layout data-fancy={isFancy}>
          <Row isButton={isButton} isRight={isRight}>
            <InViewport animationDelay="0.5s">
              <Text size={18} color="#C4BFCE">
                {description}
              </Text>
            </InViewport>
            {isButton && <InViewport animationDelay="0.5s" animationClass="scaleIn"><DoButton /></InViewport>}
          </Row>
        </Layout>
      </Container>
    </Wrapper>
  );
};
