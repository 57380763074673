import React from "react";
import { graphql } from "gatsby";
import Layout from "src/components/layout/layout";

import { TopHomeBanner } from "src/components/banner";
import { VideoCard } from "components/homeCard";
import { Portfolio, FancyHelmet } from "src/components/medeaContent";
import { ProjectsGrid } from "src/components/projectsGrid/projectsGrid";
import { OurClients, TickerLabel } from "src/components/ticker";
import { Brand } from "components/brand";
import { AnimatedText } from "components/animatedText/animatedText";
import { HelmetComponent } from "components/helmet";
import "src/api/index";
import {
  StickyWrapper,
  StickyContainer,
  StickyContainerHidden,
} from "components/stickyWrapper";
import { Title } from "components/typography/styled";

const aboutUsText = [
  "GREYAPES - THE",
  "DESIGN STUDIO",
  "THAT THINKS",
  "FOR YOU",
];
const expertiseText = [
  "Strategy",
  "Branding",
  "Content Creation",
  "Development",
];
const brandText = ["OUTSTANDING BRAND", "experience for your", "TARGET GROUP"];

const IndexPage = ({
  data: {
    allProject: { edges: data },
  },
}) => {
  return (
    <Layout key="index">
      <div id="top" />
      <HelmetComponent />
      <TopHomeBanner />
      <VideoCard
        bg="#0A0B0D"
        id="about"
        title={<AnimatedText textForAnimate={aboutUsText} />}
        description="Our passion for design and years of experience allow us to take your brand and product to another level and realise its full potential. Through our easy and flexible approach, we find individual and globally working solutions to make your brand come to life. We provide our partners with an interdisciplinary approach that allows us to come up with global solutions and bring them to life."
        linkTitle="About Us:"
        isButton
        media={<Portfolio />}
      />
      <StickyWrapper
        className="helmet-wrapper"
        height={
          typeof window !== "undefined" && window.innerWidth <= 640
            ? "90vh"
            : "200vh"
        }
      >
        <StickyContainer className="helmet-content">
          <StickyContainerHidden>
            <VideoCard
              isOverflow={false}
              id="services"
              title={<AnimatedText textForAnimate={expertiseText} />}
              description="You don't need an expert in your company who knows how to conceptualize a corporate identity. We offer complete branding and design implementation, including analysis, market research, requirements engineering, project management, content/copy writing and development if needed. In a first meeting we will define the exact framework for your needs."
              linkTitle="Our Expertise:"
              isRight
              isFancy={true}
              media={<FancyHelmet />}
            />
            <TickerLabel />
          </StickyContainerHidden>
        </StickyContainer>
      </StickyWrapper>
      <ProjectsGrid
        id="projects"
        isProjectsLink
        isBomb
        data={data.map(({ node }) => ({ ...node }))}
      />
      <Brand
        id="brand"
        title={brandText.map((e, i) => (
          <Title key={i}>{e}</Title>
        ))}
      />
      <OurClients id="clients" />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query FirstProjects {
    allProject(limit: 5) {
      totalCount
      edges {
        node {
          title
          subTitle
          imageName
          slug
          externalLink
          folder
          contents
        }
      }
    }
  }
`;
