import styled from "styled-components";
import { theme } from "src/styles/global";
import { layoutSize } from "styles/mixin";

export const Gap = styled.div`
  height: 50px;
`;

export const HiddenContainer = styled.div`
  overflow: hidden;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BrandContainer = styled.div`
  width: 100vw;
  overflow: visible;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${layoutSize};
  margin: 0 auto;
  margin-bottom: 110px;
  transform: translateX(50vw);

  @media (${theme.device.laptop}) {
    margin-bottom: 100px;
  }

  @media (${theme.device.tablet}) {
    font-size: 100%;
    flex-direction: column;
    transform: translateX(0);
    margin-bottom: 50px;
    margin-top: 50px;
  }
`;

export const TextContainer = styled.div`
  max-width: 800px;

  * [data-subtitle] {
    max-width: 680px;
    display: block;
  }

  @media (${theme.screenSize.mobile}) {
    section {
      height: 30px;
    }
  }
`;

export const Image = styled.img`
  max-width: 750px;
  width: 40vw;

  @media (${theme.device.tablet}) {
    width: 100%;
  }
`;
