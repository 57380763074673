import styled from "styled-components";
import { theme } from "src/styles/global";

export const StickyWrapper = styled.div`
  position: relative;
  height: ${({ height = "650vh" }) => height};
  background: ${({ bg = "transparent " }) => bg};

  @media (${theme.device.mobile}) {
    height: 90vh;
    min-height: 751px;
  }
`;

export const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (${theme.device.mobile}) {
    height: 90vh;
    min-height: 751px;
  }
`;

export const StickyContainerHidden = styled(StickyContainer)`
  position: static;
  overflow: hidden;
  width: 100vw;
`;
