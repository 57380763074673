import styled, { keyframes } from "styled-components";
import { theme } from "src/styles/global";
import { layoutSize } from "styles/mixin";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export const Media = styled.div`
  position: relative;
  height: ${({ isRight }) => (isRight ? 50 : 170)}px;

  @media (${theme.screenSize.mobile}) {
    max-width: 100vw;
    height: ${({ isRight }) => (isRight && 0)}px;
    ${({ isOverflow }) => (isOverflow ? `overflow: hidden;` : "")}
  }
`;

export const Container = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 110px 0;

  @media (${theme.device.tablet}) {
    align-items: flex-start;
    padding: 50px 0;
  }

  @media (${theme.device.mobile}) {
    align-items: flex-start;
    padding: 0;
    padding-bottom: 50px;
  }
`;

export const Width50 = styled.div`
  width: 50%;
`;

export const Layout = styled.div`
  ${layoutSize};
  margin: 0 auto;
  position: relative;
  z-index: 1;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  left: 50%;

  ${({ isRight }) => {
    if (isRight) return "";

    return `
      transform: translateX(calc(-100% + 250px));
      align-items: start;
      & > *:first-child {
        margin-right: 35px;
      }
    `;
  }}

  ${({ isButton }) => isButton ? "align-items: end;": ""}

  max-width: 750px;
  @media (${theme.screenSize.desktop}) {
    max-width: ${({ isRight }) => (isRight ? 530 : 730)}px;
  }

  @media (${theme.screenSize.laptop}) {
    max-width: ${({ isRight }) => (isRight ? 420 : 620)}px;
  }

  @media (${theme.screenSize.tablet}) {
    max-width: 100%;
    left: 0;
    transform: none;
  }

  @media (max-width: 640px) {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    
    > div:nth-child(2) {
      margin-top: 44px;
    }
  }
`;

export const CustomLink = styled(AnchorLink)`
  position: ${({ position = "static" }) => position};
  right: 0;
  bottom: 0;
  min-width: 80px;
  color: #c4bfce;
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
  display: block;
  &:hover {
    color: ${theme.colors.white};
  }

  & > *:first-child {
    margin-right: 15px;
  }
  & > * {
    fill: #c4bfce;
  }

  @media (${theme.device.tablet}) {
    transform: ${({ position = "static" }) =>
      position === "static"
        ? "transform: translateY(0px)"
        : "transform: translateY(40px)"};
  }

  @media (${theme.device.mobile}) {
    font-size: 14px;
    margin-bottom: ${({ isRight }) => (isRight && 26)}px;
    
    svg {
      width: 10px;
      height: 10px;
    }
  }
`;

export const Wrapper = styled.div`
  background: ${({ bg = "transparent" }) => bg};
  position: relative;
  z-index: 2;
  height: ${({ isButton }) => (isButton ? "2000vh" : "fit-content")};
  max-height: 950px;

  @media (${theme.screenSize.desktop}) {
    max-height: 950px;
  }

  @media (${theme.screenSize.laptop}) {
    max-height: 950px;
  }

  @media (${theme.screenSize.tablet}) {
    max-height: 950px;
  }

  @media (${theme.screenSize.mobile}) {
    max-height: 1200px;
  }
`;

const textRotation = keyframes`
  from {
    transform: rotate(0deg) scale(1);
  }
  to {
    transform: rotate(360deg) scale(1);
  }
`;

const textRotationScale = keyframes`
  0% {
    transform: rotate(0deg) scale(1);
  }
  10% {
    transform: rotate(36deg) scale(1.2);
  }
  100% {
    transform: rotate(360deg) scale(1.2);
  }
`;

export const DoButtonContainer = styled(AnchorLink)`
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;
  z-index: 102;

  position: relative;
  width: 200px;
  height: 200px;
  margin-left: 40px;

  @media (${theme.screenSize.tablet}) {
    margin: 0 auto 0 auto;
    justify-self: center;
  }

  @media (max-width: 640px) {
    margin-top: 75px;
  }
`;

export const RotateWrapper = styled.div`
  animation: ${textRotation} 10s linear infinite;
  /* transform-origin: center center; */
  width: 190px;
  height: 190px;
  position: absolute;
`;

export const CircleTextWrapper = styled.div`
  width: 190px;
  height: 190px;
  position: relative;
  display: flex;
  place-content: center;
  align-items: center;

  margin-block-start: 1em;
  margin-block-end: 1em;

  .circle-text {
    color: #797b8a;
    position: absolute;
    transition: transform 0.2s cubic-bezier(0.14, 0.48, 0, 0.96);
    transform: rotate(0) scale(1);
    top: 0;
    left: 0;
  }

  .border-btn {
    width: 120px;
    height: 120px;
    border: 1px dashed #54525c;
    border-radius: 50%;
  }

  .arrow {
    position: absolute;
    color: #797b8a;
  }

  .circle-text,
  .border-btn {
    transition: transform 0.3s cubic-bezier(0.14, 0.48, 0, 0.96);
  }

  &:hover .circle-text,
  &:hover .border-btn {
    transform: rotate(0) scale(1.2);
  }

  &:hover .circle-text,
  &:hover .arrow {
    color: white;
  }
`;
