import React, { useEffect, useRef, useState } from "react";
import Ticker from "react-ticker";

import { TickerLabelContainer, BigLabel, TickerWrapper } from "./styled";
import useActiveTab from "hooks/useActiveTab";

import { ScrollTrigger } from "gsap/ScrollTrigger";

export const TickerLabel = () => {
  const ref = useRef();
  const isTabActive = useActiveTab();
  const [mount, setMount] = useState(false);
  const [scale, setScale] = useState("0");

  useEffect(() => {
    if (mount && window?.innerWidth > 767) {
      ScrollTrigger.create({
        scroller: ".content-wrapper",
        trigger: ".helmet-content",
        pin: true,
        scrub: true,
        start: "top top",
        end: "+=" + window.innerHeight / 2,
        onUpdate: ({ progress }) => {
          const _progress = Math.max(0, progress);
          setScale(`${_progress}`.replace(".", ""));

          requestAnimationFrame(() => {
            ref.current.style.opacity = progress < 0.9 ? _progress : 1;
            ref.current.style.transform = `rotate(356deg) translateX(-25%) scale(${Math.min(
              1,
              Math.max(0.5, progress + 0.5)
            )})`;
          });
        },
      });
    } else {
      setMount(true);
    }
  }, [mount]);

  return (
    <TickerWrapper>
      <TickerLabelContainer ref={ref}>
        {isTabActive && (
          <Ticker key={`key-${scale}`}>
            {() => (
              <BigLabel>
                · Strategy & Plan · Strategy & Plan · Rebranding Concepts &
                Ideas · Interactive Design
              </BigLabel>
            )}
          </Ticker>
        )}
      </TickerLabelContainer>
    </TickerWrapper>
  );
};
