import React from "react";
import Ticker from "react-ticker";

import client1 from "src/assets/images/clients/1.png";
import client2 from "src/assets/images/clients/2.png";
import client3 from "src/assets/images/clients/3.png";
import client4 from "src/assets/images/clients/4.png";
import {
  SliderItem,
  TextContainer,
  TickerContainer,
  TickerItem,
  Gap,
  TickerPadding,
  OurClientContainer,
  ImageWrapper,
  Image,
} from "./styled";
import { Title } from "components/typography";
import { InViewport } from "src/components/inViewport";
import { AnimatedText } from "../animatedText/animatedText";
import useActiveTab from "hooks/useActiveTab";

const CLIENTS = [client1, client2, client3, client4];

export const OurClients = ({ id }) => {
  const isTabActive = useActiveTab();
  return (
    <OurClientContainer id={id}>
      <TextContainer>
        <Title
          title={<AnimatedText textForAnimate={["OUR CLIENTS"]} />}
          subTitle={
            <InViewport animationDelay="0.3s">
              Become our partner now and unlock your brand potential.
            </InViewport>
          }
        />
      </TextContainer>
      <TickerPadding>
        <TickerContainer>
          {isTabActive && (
            <Ticker>
              {() => (
                <TickerItem>
                  {CLIENTS.map((item, index) => (
                    <SliderItem key={index}>
                      <ImageWrapper>
                        <Image src={item} />
                      </ImageWrapper>
                    </SliderItem>
                  ))}
                </TickerItem>
              )}
            </Ticker>
          )}
        </TickerContainer>
      </TickerPadding>
      <Gap />
    </OurClientContainer>
  );
};
