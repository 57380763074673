import { useEffect, useState } from "react";

export default function useActiveTab() {
  const [isTabActive, setIsTabActive] = useState(true);

  useEffect(() => {
    const activeFunction = () => {
      if (document.hidden) {
        setIsTabActive(false);
      } else {
        setIsTabActive(true);
      }
    };

    document.addEventListener("visibilitychange", activeFunction);
    return () => {
      document.removeEventListener("visibilitychange", activeFunction);
    };
  }, []);

  return isTabActive;
}
