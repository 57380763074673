import styled from "styled-components";
import { theme } from "src/styles/global";
import { H1 } from "src/styles/components";
import { layoutSize } from "styles/mixin";

export const Gap = styled.div`
  height: 60px;

  @media (${theme.screenSize.tablet}) {
    height: 45px;
  }

  @media (${theme.screenSize.mobile}) {
    height: 30px;
  }
`;

export const BigLabel = styled(H1)`
  white-space: nowrap;
  font-size: 200px;

  text-transform: uppercase;

  @media (${theme.device.tablet}) {
    font-size: 170px;
  }

  @media (${theme.device.mobile}) {
    font-size: 120px;
  }
`;

export const OurClientContainer = styled.div`
  padding-top: 30px;
  width: 100vw;
  overflow: hidden;
`;

export const TextContainer = styled.div`
  ${layoutSize};
  margin: 0 auto;

  @media (${theme.device.mobile}) {
    margin-bottom: 30px;
    
    section {
      height: 24px;
    }
  }
`;

export const TickerWrapper = styled.div`
  width: 100vw;
  left: 0;
  overflow: hidden;
  padding: 5vw 0;
  position: absolute;
  z-index: 1;
`;

export const TickerLabelContainer = styled.div`
  opacity: 0;
  transition: opacity 0.1s linear;
  transform: rotate(356deg) translateX(-25%) scale(0.5);
  width: 210vw;
  height: 200px;

  @media (${theme.device.tablet}) {
    height: 170px;
  }

  @media (${theme.device.mobile}) {
    height: 120px;
  }
`;

export const TickerPadding = styled.div`
  padding: 5vw 0;

  @media (${theme.screenSize.desktop}) {
    padding: 6vw 0;
  }

  @media (${theme.screenSize.laptop}) {
    padding: 6vw 0;
  }

  @media (${theme.screenSize.tablet}) {
    padding: 10vw 0;
  }

  @media (${theme.screenSize.mobile}) {
    padding: 5vw 0;
  }
`;

export const TickerContainer = styled.div`
  width: 110vw;
  transform: rotate(354deg) translateX(-5vw);
  border-top: 1px solid ${theme.colors.mulledWine};
  border-bottom: 1px solid ${theme.colors.mulledWine};

  max-height: 380px;
  height: 20vw;

  @media (${theme.screenSize.desktop}) {
    height: 23.75vw;
  }

  @media (${theme.screenSize.laptop}) {
    max-height: 290px;
    height: 24vw;
  }

  @media (${theme.screenSize.tablet}) {
    max-height: 290px;
    height: 37.7vw;
  }

  @media (${theme.screenSize.mobile}) {
    max-height: 250px;
    min-height: 250px;
    height: 20vw;
  }
`;

export const TickerItem = styled.div`
  display: flex;
  margin-left: -2vw;
`;

export const SliderItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${theme.colors.mulledWine};

  max-width: 380px;
  max-height: 380px;
  width: 20vw;
  height: 20vw;

  @media (${theme.screenSize.desktop}) {
    width: 23.75vw;
    height: 23.75vw;
  }

  @media (${theme.screenSize.laptop}) {
    max-width: 290px;
    max-height: 290px;
    width: 24vw;
    height: 24vw;
  }

  @media (${theme.screenSize.tablet}) {
    max-width: 290px;
    max-height: 290px;
    width: 37.7vw;
    height: 37.7vw;
  }

  @media (${theme.screenSize.mobile}) {
    max-width: 250px;
    max-height: 250px;
    min-height: 250px;
    min-width: 250px;
    width: 20vw;
    height: 20vw;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  max-width: 80%;
  max-height: 80%;
`;
