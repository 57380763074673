import React from "react";
import PortfolioClipX2 from "src/assets/videos/projectsx20.webm";

import { PortfolioVideo } from "./styled";

export const Portfolio = () => {
  return (
    <PortfolioVideo
      src={PortfolioClipX2}
      preload="preload"
      loop
      autoPlay
      muted
      played
    />
  );
};
