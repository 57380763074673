import styled from "styled-components";
import { theme } from "src/styles/global";

export const HelmetImg = styled.img`
  width: 100%;
  height: 100%;
  transform: translateX(-5vw);
  object-fit: contain;
`;

export const PortfolioVideo = styled.video`
  width: 100vw;
  height: auto;
  transform: translateY(-50%);

  @media (${theme.screenSize.desktop}) {
  }

  @media (${theme.screenSize.laptop}) {
  }

  @media (${theme.screenSize.tablet}) {
  }

  @media (${theme.screenSize.mobile}) {
    transform: translateY(0%) scale(2);
    transform-origin: center;
    height: 100%;
  }
`;

export const PortfolioImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;


export const HelmetContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100vw;
  max-width: 100vw;
  max-height: 680px;
  object-fit: contain;
  transform: translate(-15%, -75%);

  @media (${theme.screenSize.fullScreen}) {
    height: 50vw;
    max-height: 100vh;
    transform: translateY(-60%);
  }

  @media (${theme.screenSize.desktop}) {
    height: 50vw;
    max-height: 100vh;
    transform: translateY(-50%);
  }

  @media (${theme.screenSize.laptop}) {
    height: 70vw;
    max-height: 100vh;
    transform: translateY(-50%);
  }

  @media (${theme.screenSize.tablet}) {
    transform: translate(20vw, calc(-60% + 0px));
    width: 80vw;
  }

  @media (${theme.screenSize.mobile}) {
    transform: translate(12.5%, calc(-100% + 0px));
    width: 80vw;
  }

  & [data-fancy] {
    transition: opacity 1s cubic-bezier(0.14, 0.48, 0, 0.96);
  }
`;

export const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: scale(1);

  z-index: 101;
`;


export const HelmetImgLast = styled.img`
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: scale(1);

  z-index: 1002;
`
