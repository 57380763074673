import React, { useEffect, useState } from "react";
import { gsap } from "gsap";
import {
  TextContainer,
  BrandContainer,
  Image,
  HiddenContainer,
} from "./styled";
import { Title } from "components/typography";
import FrogImage from "assets/images/frog.png";

export const Brand = ({ id, title }) => {
  const [mount, setMount] = useState(false);
  useEffect(() => {
    if (mount && window?.innerWidth > 768) {
      gsap.to(".brand-container", {
        scrollTrigger: {
          scroller: ".content-wrapper",
          trigger: ".brand-container",
          start: "top bottom",
          scrub: true,
        },
        duration: 1,
        x: -0.5 * Math.max(window.innerWidth, window.innerHeight),
        ease: "none",
      });
    } else {
      setMount(true);
    }
  }, [mount]);

  return (
    <HiddenContainer>
      <BrandContainer id={id} className="brand-container">
        <Image src={FrogImage} />
        <TextContainer>
          <Title
            gap={50}
            title={title}
            subTitle={
              <>
                GreyApes design studio offers complete solutions, from
                conception to implementation, that individualise your brand and
                position your company on the market in the long term.
                Out-of-the-box ideas combined with exceptional design and
                cutting-edge technology will unlock the potential of your brand,
                make it memorable and achieve top of mind awareness.
              </>
            }
          />
        </TextContainer>
      </BrandContainer>
    </HiddenContainer>
  );
};
