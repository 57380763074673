import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Canvas, HelmetContainer, HelmetImgLast } from "./styled";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import EyeHelmet from "assets/images/eyeHelmet.png";

const cache = {};

const frameCount = 61;
const currentFrame = (index) => {
  return cache[index] || `projects/helmet/Helmet-01_${index}.png`;
};

const preloadImages = () => {
  for (let i = 0; i < frameCount; i++) {
    // const img = new Image();
    // img.src = currentFrame(i);
    fetch(currentFrame(i))
      .then((r) => r.blob())
      .then((blob) => {
        var reader = new FileReader();
        reader.onload = function () {
          cache[i] = this.result;
        };
        reader.readAsDataURL(blob);
      });
  }
};

if (typeof window !== "undefined") {
  preloadImages();
}

export const FancyHelmet = () => {
  const html = useRef();
  const canvas2d = useRef();
  const context2d = useRef();
  const img = useRef();
  const helmetImg = useRef();

  const [mount, setMount] = useState(false);

  useEffect(() => {
    context2d.current = canvas2d.current?.getContext("2d");
    canvas2d.current.width = 600;
    canvas2d.current.height = 600;

    img.current = new Image();
    const url = currentFrame(0);
    img.current.src = url;
    img.current.onload = function () {
      context2d.current?.drawImage(img.current, 0, 0);
    };

    if(typeof window !== 'undefined' && window.innerWidth <= 640) {
      helmetImg.current.src = currentFrame(0);
    }
  }, []);

  useEffect(() => {
    if (mount) {
      const isMobile = window?.innerWidth < 768;
      ScrollTrigger.create({
        scroller: ".content-wrapper",
        trigger: ".helmet-content",
        pin: true,
        scrub: true,
        start: "top top",
        end: "+=" + window.innerHeight,
      });

      ScrollTrigger.create({
        scroller: ".content-wrapper",
        trigger: ".helmet-content",
        scrub: true,
        start: "top bottom",
        end: "+=" + 2 * window.innerHeight,
        onUpdate: ({ progress }) => {
          const progress04 = (progress - 0.4) / 0.4;
          const progress05 = (progress - 0.5) / 0.5;

          const scale = isMobile ? 1 : Math.max(1, 12 * progress04);
          const translateX = isMobile ? 1 : 5 * Math.max(0, 5 * progress05);
          const translateY = isMobile ? 1 : -4 * Math.max(0, 5 * progress05);

          const frameIndex = Math.min(
            Math.ceil((progress / (isMobile ? 1 : 0.5)) * frameCount),
            frameCount
          );

          if (!isMobile) {
            document.querySelectorAll('[data-fancy="true"]').forEach((e) => {
              if (isMobile) {
                e.style.opacity = 1 - Math.max(0, progress04);
              } else {
                e.style.opacity = 1 - Math.max(0, 5 * progress04);
                e.style.transform = `translateX(${Math.max(
                  0,
                  50 * progress04
                )}vw)`;
              }
            });
          }

          // document.querySelectorAll("[data-media]").forEach((e) => {});

          requestAnimationFrame(() => {
            try {
              img.current.src = currentFrame(frameIndex + 1);
              context2d.current.drawImage(img.current, 0, 0);
              canvas2d.current.style.zIndex = scale;

              if (frameIndex < frameCount) {
                canvas2d.current.style.transform = `translate(${translateX}%, ${translateY}%) scale(${scale})`;
                canvas2d.current.style.opacity = 1;
                helmetImg.current.style.opacity = 0;
              } else {
                const p = isMobile
                  ? 0
                  : Math.max(0, (5 * (progress - 0.8)) / 0.8);

                helmetImg.current.style.opacity = 1 - p;
                canvas2d.current.style.opacity = 0;
                helmetImg.current.style.transform = `translate(${translateX}%, ${translateY}%) scale(${scale})`;
              }
            } catch (_) {}
          });
        },
      });
    } else {
      setMount(true);
    }
  }, [mount]);

  return (
    <HelmetContainer>
      <Canvas ref={canvas2d} />
      <HelmetImgLast ref={helmetImg} src={EyeHelmet} />
      <div
        ref={html}
        style={{
          height: 1,
          position: "relative",
          width: "100%",
        }}
      ></div>
    </HelmetContainer>
  );
};
